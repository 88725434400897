.main-container {
  height: 100vh;
}

.main-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: 100%;
  background-color: rgba(119, 41, 41, 0.125);
  -webkit-animation: 15s 1s;
  animation: 15s 1s;
}

.container {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  color: azure;
  font-weight: 600;
}

.counter {
  font-size: 150px;
  color: azure;
  font-weight: 500;
}

.button:hover {
  background-color: rgb(181, 181, 187);
}

.button {
  display: inline-block;
  border-radius: 8px;
  background-color: white;
  border: none;
  color: #485fc7;
  font-size: 18px;
  width: 150px;
  height: 50px;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.pause-buton {
  display: inline-block;
  border-radius: 8px;
  background-color: white;
  border: none;
  color: #485fc7;
  height: 50px;
  width: 50px;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: 5px;
}
.button-notSpeaking {
  display: inline-block;
  border-radius: 8px;
  background-color: white;
  border: none;
  color: #485fc7;
  height: 50px;
  width: 50px;
  margin: 5px;
  transition: all 0.5s;
  cursor: pointer;
}
.button-notSpeaking:hover {
  background-color: rgb(181, 181, 187);
}
.pause-buton:hover {
  background-color: rgb(181, 181, 187);
}

.butons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-container {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
}

.header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.count-container {
  display: flex;
}

.plus {
  font-size: 140px;
  color: azure;
  font-weight: 500;
  margin-right: 10px;
}
.right-container {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}
.close-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: rgb(255, 255, 255);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close-btn:hover {
  transform: rotate(180deg);
}
.speaker-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text-speaker {
  font-size: 40px;
  font-weight: 600;
  color: white;
  padding-inline: 10px;
}
.speaker-count {
  margin-bottom: 55px;
  color: white;
  height: 25px;
}
