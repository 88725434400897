body {
  margin: 0;
}

@font-face {
  font-family: Urbanist;
  src: url(font/Urbanist-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Urbanist;
  src: url(font/Urbanist-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Urbanist;
  src: url(font/Urbanist-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}
