.finish-body {
  height: 100vh;
  background-color: #485fc7;
}
.meet-complete {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 2.375rem;
  color: white;
}
