.body {
  flex-direction: column;
  display: flex;
  height: 100vh;
}

.header {
  border-bottom: 1px solid #dfdfdf;
}

.header__toolbar {
  display: flex;
  align-items: center;
}

.logo {
  height: 48px;
  margin-right: 12px;
}

.header {
  font-size: 32px;
  font-weight: 500;
  color: #1e4a59;
}

.content {
  height: calc(100vh - 64px);
  padding: 12px 24px;
  display: flex;
}

.content > div,
.content > div > div {
  flex: 1;
  height: 100%;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("https://macellan.net/image/hero-image-1.webp");
  background-size: contain;
  opacity: 0.04;
  z-index: -1;
}

.card {
  padding: 1rem 1.4rem ;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.05) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 10px !important;
}

.card__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card__actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start !important;
}

.people-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.card__content form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.label {
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.input {
  width: 200px;
  border: 0;
  outline: none;
  padding-left: 10px;
}

.button {
  height: 54px;
}

.text-home {
  margin-top: 15px;
  color: #1e4a59;
}
.report-icon {
  width: 20px;
  height: 20px;
}
.report-view {
  margin-top: 20px;
}